<template>
  <template v-if="(typeof product.productId !== 'undefined')">
    <van-swipe :autoplay="3000" indicator-color="white" lazy-render>
      <van-swipe-item v-for="(item, index) in product.swipes" :key="index">
        <van-image :src="item + '?imageView2/1/w/600/h/600/q/100'" style="vertical-align:top;" />
      </van-swipe-item>
    </van-swipe>
    <div style="padding:15px;background:#fff;">
      <h4 style="color:#ff5000;">
        <price :price="product.price" :scale="1" />
      </h4>
      <van-row style="margin:10px 0 0 0;">
        <van-col span="19">
          <h5>{{ product.name }}</h5>
        </van-col>
        <van-col span="5" style="text-align:right;">
          <van-button icon="good-job-o" size="small" plain hairline @click="onShare()">分享</van-button>
        </van-col>
      </van-row>
      <p v-if="product.description !== ''" style="color:#666;line-height:1.8;margin:8px 0 0 0;">
        <small>{{ product.description }}</small>
      </p>
      <van-row style="margin:8px 0 0 0;">
        <van-col span="8">
          <small style="color:#999;">已售 {{ product.sales + product.fictitiousSales }} 笔</small>
        </van-col>
        <van-col span="8" style="text-align:center;">
          <small style="color:#999;">评论 {{ comment.total }}</small>
        </van-col>
        <van-col span="8" style="text-align:right;">
          <small style="color:#999;">好评率 {{ (comment.favorableRating * 100).toFixed(1) }}%</small>
        </van-col>
      </van-row>
    </div>
    <van-notice-bar left-icon="volume-o" :scrollable="false" v-if="product.firstPurchaseDiscount !== 1 || product.repurchaseDiscount !== 1">
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="3000"
        :show-indicators="false"
      >
        <van-swipe-item v-if="product.firstPurchaseDiscount !== 1">初次购买享 {{ product.firstPurchaseDiscount * 10 }} 折</van-swipe-item>
        <van-swipe-item v-if="product.repurchaseDiscount !== 1">复购享 {{ product.repurchaseDiscount * 10 }} 折</van-swipe-item>
      </van-swipe>
    </van-notice-bar>
    <van-notice-bar left-icon="volume-o" :scrollable="false" v-if="reductions.length > 0">
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="3000"
        :show-indicators="false"
      >
        <van-swipe-item v-for="item in reductions" :key="item">单笔订单满 {{ item.fullAmount }} 减 {{ item.discountAmount }}</van-swipe-item>
      </van-swipe>
    </van-notice-bar>
    <van-notice-bar left-icon="volume-o" :scrollable="false" v-if="rankDiscounts.length > 0">
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="3000"
        :show-indicators="false"
      >
        <van-swipe-item v-for="item in rankDiscounts" :key="item">该商品前 {{ item.rank }} 名下单，享 {{ item.discount * 10 }} 折</van-swipe-item>
      </van-swipe>
    </van-notice-bar>
    <van-cell-group>
      <!-- <van-cell v-if="product.groupon" :title="product.grouponParticipants + '人起团购只要&yen;' + (product.price * product.grouponDiscount).toFixed(1) / 1 + '（限' + product.grouponDays + '日内成团）'" icon="fire-o" is-link @click="groupon.show = true" /> -->
      <van-cell v-if="product.purchaseLimit !== 0" :title='"每人限购" + product.purchaseLimit + "件"' icon="cart-circle-o" />
      <van-cell v-if="!product.allowRefund" style="color: #FF0000;" title="该商品不允许退款,请谨慎购买!" icon="info-o" />
      <van-cell v-if="ranking > 0" :title='"本店热销排行榜 NO " + ranking' icon="bar-chart-o" />
      <van-cell title="线下实体店" icon="location-o" is-link :to="{ name: 'Outlet', query: { storeId: store.storeId } }" />
    </van-cell-group>
    <div style="margin: 7px 0 0 0;background:#fff;padding:15px;">
      <van-row>
        <van-col span="6">
          <van-image
            radius="5"
            width="4rem"
            height="4rem"
            :src="store.logo"
          />
        </van-col>
        <van-col span="18">
          <van-row>
            <van-col span="16">
              <h5>{{ store.name }}</h5>
              <van-tag type="danger">新店入驻</van-tag>
              <!--
              <van-tag type="danger">优质店铺</van-tag>
              <van-tag type="danger">人气店铺</van-tag>
              -->
            </van-col>
            <van-col span="8" style="text-align:right;">
              <van-button plain size="small" :to="'/?storeId=' + store.storeId">进店逛逛</van-button>
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
    <van-cell :title="'购买记录(' + buys.count + ')'" style="margin:7px 0 0 0;" />
    <div style="padding:15px;background:#fff;">
      <template v-for="v in buys.items" :key="v">
        <van-row style="padding:7px 0;color:#999">
          <van-col span="8">
            <small>{{ v.buyer.name !== '' ? v.buyer.name.substring(0, 2) + '**' : '****' }}</small>
          </van-col>
          <van-col span="8" style="text-align:center;">
            <small>+{{ v.productQuantity }}</small>
          </van-col>
          <van-col span="8" style="text-align:right;">
            <small>{{ v.createTime.substring(0, 10) }}</small>
          </van-col>
        </van-row>
      </template>
    </div>
    <van-cell :title="`评论 ${comment.total}条`" :value="`好评率 ${(comment.favorableRating * 100).toFixed(1)}%`" is-link style="margin:7px 0 0 0;" />
    <div style="padding:15px;background:#fff;">
      <van-tag round size="medium" color="#ffe1e1" text-color="#000">好评（{{ comment.good }}）</van-tag>&nbsp;
      <van-tag round size="medium" color="#ffe1e1" text-color="#000">中评（{{ comment.general }}）</van-tag>&nbsp;
      <van-tag round size="medium" color="#ffe1e1" text-color="#000">差评（{{ comment.bad }}）</van-tag>&nbsp;
      <template v-for="v in comment.items" :key="v">
        <comment-item :item="v" />
      </template>
      <div style="text-align:center;" v-if="comment.total > 2">
        <van-button size="small" round :to="{ name: 'ProductComment', query: { productId: productId } }">查看全部评论</van-button>
      </div>
    </div>
    <van-divider>商品详情</van-divider>
    <div>
      <div v-show="product.detailText !== ''" style="padding:15px;background:#fff;">{{ product.detailText }}</div>
      <template v-for="item in product.detailImages" :key="item">
        <van-image :src="item" width="100%" style="vertical-align: middle;"/>
      </template>
    </div>
    <van-divider>其他推荐</van-divider>
    <list :store-id="storeId" />
    <div style="height:70px;"></div>
    <van-action-bar>
      <van-action-bar-icon icon="shop-o" text="店铺" to="/" />
      <van-action-bar-icon icon="cart-o" text="购物车" to="/cart" />
      <van-action-bar-button type="warning" text="加入购物车" @click="onBuy('cart', '')" />
      <van-action-bar-button type="danger" text="立即购买" @click="onBuy('quick', '')" />
    </van-action-bar>
    <van-popup v-model:show="groupon.show" :style="{ width: '80%', background: '#fff' }">
      <div style="margin:15px;">
        <template v-if="groupon.teams.length > 0">
          <h5 style="padding:15px;;background:#fff;margin:15px 0 0 0;">他们正在拼单中，可直接参与</h5>
          <template v-for="item in groupon.teams" :key="item">
            <div style="padding:15px;background:#fff;" class="van-hairline--top">
              <van-row>
                <van-col span="12">
                  <template v-for="(order, index) in item.orders" :key="order">
                    <template v-if="index < 5">
                      <van-image
                        round
                        width="2.5rem"
                        height="2.5rem"
                        fit="cover"
                        :src="order.buyer.avatar"
                        style="border:1px solid #fff;margin:0 0 0 -5px;vertical-align:middle"
                      />
                    </template>
                  </template>
                </van-col>
                <van-col span="12">
                  <van-row style="text-align:right;">
                    <van-col span="24">
                      <van-button type="primary" size="small" style="margin:7px 0 0 0;" @click="onBuy('groupon', item.grouponId)">差{{ item.minParticipants - item.currentParticipants}}人，去拼单</van-button>
                    </van-col>
                  </van-row>
                </van-col>
              </van-row>
            </div>
          </template>
        </template>
        <template v-else>
          当前没有可参与的团
        </template>
        <van-button type="primary" block style="margin:15px 0 0 0;" @click="onBuy('groupon', '0')">创建新团</van-button>
      </div>
    </van-popup>
    <van-popup
      v-model:show="sku.show"
      position="bottom"
      :style="{ maxHeight: '90%' }"
    >
      <sku :id="productId" @select="onSelect" />
    </van-popup>
    <van-dialog v-model:show="groupon.invitationShow" title="" show-cancel-button @confirm="onBuy('groupon', groupon.invitationGroupon.grouponId)">
      <div style="margin:15px;">
        <van-image :src="groupon.invitationGroupon.buyer.avatar" width="2.5rem" height="2.5rem" style="vertical-align:middle;" />
        &nbsp;<h5 style="display:inline;">{{ groupon.invitationGroupon.buyer.nickname }} 邀你一起拼单</h5>
      </div>
    </van-dialog>
  </template>
</template>

<script>
import { toRefs, reactive, onMounted, inject, watch, nextTick } from 'vue'
import List from './components/List'
import Sku from './components/Sku'
import { Toast, Dialog } from 'vant'
// import { createCart } from '@/api/cart'
import Price from '@/components/Price'
import CommentItem from '../../components/CommentItem'

export default {
  components: {
    Price,
    List,
    Sku,
    CommentItem
  },
  setup (props, context) {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const Cookies = inject('Cookies')
    const state = reactive({
      productId: parseInt(useRoute.query.productId),
      product: {
        swipes: []
      },
      store: {},
      reductions: [],
      hasSku: false,
      hasDate: false,
      biz: 'TRANSPORT',
      ranking: 0,
      page: 0,
      finished: false,
      loading: false,
      list: [],
      rankDiscounts: [],
      groupon: {
        invitationShow: false,
        invitationGroupon: {},
        show: false,
        teams: [],
        teamId: 0 // 0代表新建，其他代表组id
      },
      groupons: [],
      sku: {
        show: false
      },
      buyTo: 'cart',
      buyAction: '',
      buyParam: undefined,
      from: Cookies.get('from'),
      storeId: Number.parseInt(Cookies.get('storeId')),
      time: 30 * 60 * 60 * 1000,
      buys: {},
      comment: {
        bad: 0,
        favorableRating: 1,
        general: 1,
        good: 0,
        total: 1,
        items: []
      }
    })
    const onShare = () => {
      useRouter.push({
        name: 'ProductShare',
        query: {
          productId: state.product.productId
        }
      })
    }
    const onBuy = (action, param) => {
      state.buyAction = action
      state.buyParam = param
      state.sku.show = true
      // if (state.hasSku) {
      //   state.sku.show = true
      // } else {
      //   onSelect({
      //     productId: state.product.productId,
      //     productQuantity: 1,
      //     productSkuId: '',
      //     productDayId: ''
      //   })
      // }
    }
    const onSelect = (value) => {
      state.sku.show = false
      const products = []
      products.push({
        productId: value.productId,
        quantity: value.productQuantity,
        skuId: value.productSkuId,
        skuDayId: value.productSkuDayId
      })
      if (state.buyAction === 'groupon') {
        products[0].grouponId = state.buyParam
        useRouter.push({
          name: 'OrderForm',
          query: {
            products: JSON.stringify(products)
          }
        })
      } else if (state.buyAction === 'quick') {
        useRouter.push({
          name: 'OrderForm',
          query: {
            products: JSON.stringify(products)
          }
        })
      } else if (state.buyAction === 'cart') {
        Toast.loading({ duration: 0, forbidClick: true })
        post('/cart.create', {
          buyerId: Cookies.get('buyerId'),
          productId: value.productId,
          skuId: value.productSkuId,
          skuDayId: value.productSkuDayId,
          quantity: value.productQuantity
        }).then(res => {
          Toast.clear()
          if (res.code === 0) {
            Dialog.confirm({
              title: '提示',
              message: '已添加至购物车',
              confirmButtonText: '去结算',
              cancelButtonText: '继续挑选'
            }).then(() => {
              useRouter.push({
                name: 'Cart',
                query: {}
              })
            }).catch(() => {
            })
          } else {
            // Toast(res.msg)
            Toast('已添加至购物车')
          }
        })
      }
    }
    const getProduct = (id) => {
      Toast.loading({
        forbidClick: true
      })
      post('/product.get', {
        productId: id
      }).then(res => {
        console.log('goods')
        state.product = Object.assign({}, res.data)
        state.product.swipes.unshift(state.product.image)
        state.biz = state.product.bizType
        state.rankDiscounts = state.product.rankDiscounts
        if (state.biz === 'TRANSPORT') {
          state.addCart = true
        }
        if (res.data.skus.length > 0) {
          state.hasSku = true
        } else {
          state.hasSku = false
        }
        if (JSON.stringify(res.data.skus).includes('dayId')) {
          state.hasDate = true
        }
      })
      post('/product.list', {
        bizScope: 'GENERAL',
        sortOrder: 'DESC',
        sortField: 'sales',
        storeId: state.storeId
      }).then(res => {
        state.ranking = 0
        for (let index = 0; index < res.data.content.length; index++) {
          if (res.data.content[index].productId === state.productId) {
            state.ranking = index + 1
            break
          }
        }
      })
    }
    const init = () => {
      console.log(useRouter)
      getProduct(state.productId)
      // post('/store.listReduction', {
      //   storeId: state.storeId
      // }).then(res => {
      //   state.reductions = res.data.content
      // })
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        state.store = res.data
        state.reductions = res.data.reductions
      })
      post('/order.list', {
        productId: state.productId,
        pageSize: 5,
        effect: true
      }).then(res => {
        state.buys = {
          items: res.data.content,
          count: res.data.totalElements
        }
      })
      post('/order.list', {
        productId: state.productId,
        comment: true,
        pageSize: 2
      }).then(res => {
        for (const v of res.data.content) {
          state.comment.items.push({
            avatar: v.buyer.avatar,
            name: v.buyer.name,
            content: v.commentContent,
            time: v.commentTime
          })
        }
      })
      post('/order.sumComment', {
        productId: state.productId
      }).then(res => {
        console.log(res)
        state.comment.favorableRating = res.data.favorableRating
        state.comment.general = res.data.general
        state.comment.good = res.data.good
        state.comment.bad = res.data.bad
        state.comment.total = res.data.total
      })
      // listGrouponWithOrder({
      //   productId: state.productId,
      //   grouponIds: useRoute.query.groupon_id ? useRoute.query.groupon_id.split(',') : undefined,
      //   status: 'STARTED'
      // }).then(res => {
      //   state.groupon.teams = res.data.content
      // })
      // if (typeof useRoute.query.grouponId !== 'undefined') {
      //   getGroupon({
      //     grouponId: useRoute.query.grouponId
      //   }).then(res => {
      //     state.groupon.invitationGroupon = res.data
      //     state.groupon.invitationShow = true
      //   })
      // }
    }
    onMounted(() => {
      init()
    })
    nextTick(() => {
      // console.log('123')
      // const myPlayer = Videojs(document.getElementById('myVideo'), {
      //   controls: true,
      //   autoplay: false,
      //   preload: 'auto',
      //   controlBar: {
      //     playToggle: true
      //   }
      // })
      // myPlayer.on('ended', function () {
      // })
    })
    watch(() => useRoute.query.productId, (to, previous) => {
      if (to) {
        Toast.clear()
        state.productId = +to
        document.documentElement.scrollTop = document.body.scrollTop = 0
        init()
      }
    })
    return {
      ...toRefs(state),
      onBuy,
      onSelect,
      onShare
    }
  }
}
</script>

<style scoped lang="less">
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
</style>
