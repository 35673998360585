import request from '@/util/request'

export function listCart (data) {
  return request({
    url: '/general.listCart',
    method: 'post',
    data
  })
}

export function getCart (data) {
  return request({
    url: '/general.getCart',
    method: 'post',
    data
  })
}

export function createCart (data) {
  return request({
    url: '/general.createCart',
    method: 'post',
    data
  })
}

export function updateCart (data) {
  return request({
    url: '/general.updateCart',
    method: 'post',
    data
  })
}

export function deleteCart (data) {
  return request({
    url: '/general.deleteCart',
    method: 'post',
    data
  })
}
