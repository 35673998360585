<template>
  <div style="background:#fff;padding:0 15px 15px 15px;overflow:hidden;">
    <div v-if="specs.length > 0">
      <h5 style="margin:15px 0 0 0;">{{ specs[0] }}</h5>
      <ul>
        <li v-for="(item) in fxs[0]" :key="item">
          <small :class="[{'label-disable': s[0].indexOf(item) < 0}, {'label-available': s[0].indexOf(item) > -1}, {'label-active': s1 === item}]" @click="onClickText(0, s1 === item ? '' : item, s[0].indexOf(item) < 0)">{{ item }}</small>
        </li>
      </ul>
    </div>
    <div v-if="specs.length > 1">
      <h5 style="margin:15px 0 0 0;">{{ specs[1] }}</h5>
      <ul>
        <li v-for="(item) in fxs[1]" :key="item">
          <small :class="[{'label-disable': s[1].indexOf(item) < 0}, {'label-available': s[1].indexOf(item) > -1}, {'label-active': s2 === item}]" @click="onClickText(1, s2 === item ? '' : item, s[1].indexOf(item) < 0)">{{ item }}</small>
        </li>
      </ul>
    </div>
    <div v-if="specs.length > 2">
      <h5 style="margin:15px 0 0 0;">{{ specs[2] }}</h5>
      <ul>
        <li v-for="(item) in fxs[2]" :key="item">
          <small :class="[{'label-disable': s[2].indexOf(item) < 0}, {'label-available': s[2].indexOf(item) > -1}, {'label-active': s3 === item}]" @click="onClickText(2, s3 === item ? '' : item, s[2].indexOf(item) < 0)">{{ item }}</small>
        </li>
      </ul>
    </div>
    <div v-if="specs.length > 3">
      <h5 style="margin:15px 0 0 0;">{{ specs[3] }}</h5>
      <ul>
        <li v-for="(item) in fxs[3]" :key="item">
          <small :class="[{'label-disable': s[3].indexOf(item) < 0}, {'label-available': s[3].indexOf(item) > -1}, {'label-active': s4 === item}]" @click="onClickText(3, s14 === item ? '' : item, s[3].indexOf(item) < 0)">{{ item }}</small>
        </li>
      </ul>
    </div>
    <div v-if="specs.length > 4">
      <h5 style="margin:15px 0 0 0;">{{ specs[4] }}</h5>
      <ul>
        <li v-for="(item) in fxs[4]" :key="item">
          <small :class="[{'label-disable': s[4].indexOf(item) < 0}, {'label-available': s[4].indexOf(item) > -1}, {'label-active': s5 === item}]" @click="onClickText(4, s5 === item ? '' : item, s[4].indexOf(item) < 0)">{{ item }}</small>
        </li>
      </ul>
    </div>
  </div>
  <div class="calendar" v-if="hasDay">
    <h5 style="padding:15px;text-align:left;">请选择日期</h5>
    <van-tabs v-model="active">
      <template v-for="itemM in month" :key="itemM">
        <van-tab :title="itemM.mAlias">
          <van-grid :border="false" :column-num="7">
            <template v-for="(itemWeek, indexWeek) in week" :key="'week-' + indexWeek">
              <van-grid-item>
                <small>{{ itemWeek }}</small>
              </van-grid-item>
            </template>
          </van-grid>
          <van-grid :border="false" :column-num="7">
            <template v-for="(itemIndent, indexIndent) in parseInt(itemM.first)" :key="'indent-' + indexIndent">
              <van-grid-item>
                <h5>&nbsp;</h5>
              </van-grid-item>
            </template>
            <template v-for="(itemD, indexD) in itemM.days" :key="itemD">
              <template v-if="dates.indexOf(itemD) > -1">
                <template v-if="skus.length === 1">
                  <van-grid-item :class="['day-available', {'day-active': itemD === curDate}]" @click="onClickDate(itemD)">
                    <h6>{{ skus[0].days.filter(v => v.day === itemD)[0].stock }} 件</h6>
                    <h5>{{ indexD + 1 }}</h5>
                    <h6>&yen;{{ skus[0].days.filter(v => v.day === itemD)[0].price }}</h6>
                  </van-grid-item>
                </template>
                <template v-else-if="curDate !== ''">
                  <van-grid-item :key="'day-' + indexD" :class="['day-available', {'day-active': itemD === curDate}]" @click="onClickDate(itemD)">
                    <h5>{{ indexD + 1 }}</h5>
                  </van-grid-item>
                </template>
                <template v-else>
                  <van-grid-item class="day-available" @click="onClickDate(itemD)">
                    <h5>{{ indexD + 1 }}</h5>
                  </van-grid-item>
                </template>
              </template>
              <template v-else>
                <van-grid-item class="day-disable">
                  <h5>{{ indexD + 1 }}</h5>
                </van-grid-item>
              </template>
            </template>
          </van-grid>
        </van-tab>
      </template>
    </van-tabs>
  </div>
  <div style="height:120px;"></div>
  <div style="position:fixed;bottom:0;width:100%;">
    <van-cell-group>
      <van-cell center title="购买数量" style="margin:15px 0 0 0;">
        <van-stepper v-model="qty" min="1" :max="stock" integer disabled-input />
      </van-cell>
    </van-cell-group>
    <div style="background:#fff;padding:15px;">
      <van-row>
        <van-col span="8">
          <h5 style="color:#ee0a24;margin:5px 0 0 0;">
            <template v-if="price > 0">
              &yen; {{ price }}
              <template v-if="goods.grouponEnable">
                <div>
                  拼团只要 &yen; {{ (price * goods.grouponDiscount).toFixed(2) }}
                </div>
              </template>
            </template>
          </h5>
        </van-col>
        <van-col span="16" style="text-align:right">
          <!--
          <van-button size="small" @click="onAddCart">加入购物车</van-button>&nbsp;
          <van-button size="small" @click="onBuy">立即购买</van-button>
          -->
          <van-button size="small" @click="onAddCart">加入购物车</van-button>&nbsp;
          <van-button size="small" @click="onBuy2">立即购买</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
  <van-action-bar>
    <van-action-bar-button type="danger" :text="price" @click="onBuy2"/>
  </van-action-bar>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { createCart } from '@/api/cart'
import { Toast, Dialog } from 'vant'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'
import { post } from '@/util/request'

export default {
  components: {
  },
  props: {
    id: Number,
    groupon: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select'],
  setup (props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      h: route.query.product_id,
      gid: props.id,
      goods: {},
      specs: [],
      skus: [],
      skusf: [],
      fxs: [],
      s: [],
      s1: '',
      s2: '',
      s3: '',
      s4: '',
      s5: '',
      hasDay: false,
      month: [],
      week: ['日', '一', '二', '三', '四', '五', '六'],
      curDate: '',
      dates: [],
      active: 0,
      price: '选择规格',
      stock: 0,
      qty: 0,
      skuId: '',
      skuDayId: '',
      ok: false
    })
    const onAddCart = () => {
      if (state.ok) {
        createCart({
          buyerId: Cookies.get('buyer_id'),
          productId: state.gid,
          skuId: state.skuId,
          skuDayId: state.skuDayId,
          quantity: state.qty
        }).then(res => {
          if (res.code === 0) {
            Dialog.confirm({
              title: '提示',
              message: '已添加至购物车',
              confirmButtonText: '去结算',
              cancelButtonText: '继续挑选'
            }).then(() => {
              router.push({
                path: '/cart',
                query: {}
              })
            }).catch(() => {
            })
          } else {
            Toast(res.msg)
          }
        })
      }
    }
    const onBuy = () => {
      if (state.ok) {
        router.push({
          path: '/order/form',
          query: {
            products: state.gid + ',' + state.qty + ',' + state.skuId + ',' + state.skuDayId
          }
        })
      }
    }
    const onBuy2 = () => {
      if (state.ok) {
        emit('select', {
          productId: state.gid,
          productQuantity: state.qty,
          productSkuId: state.skuId ?? '',
          productSkuDayId: state.skuDayId ?? ''
        })
      }
    }
    const onClickDate = (val) => {
      if (state.curDate !== '' && state.curDate === val) {
        state.curDate = ''
        filterG()
      } else if (state.curDate !== '' && state.curDate !== val) {
        state.curDate = ''
        filterG()
        state.curDate = val
        unfilterG(val)
      } else {
        state.curDate = val
        unfilterG(val)
      }
    }
    const unfilterG = (val) => {
      const temp = []
      for (const item of state.skus) {
        for (const item2 of item.days) {
          if (item2.day === val) {
            temp.push(item)
            break
          }
        }
      }
      state.skus = temp
      filterG()
    }
    const filterDate = () => {
      let temp = state.skus
      if (state.s1 === '' && state.s2 === '' && state.s3 === '' && state.s4 === '' && state.s5 === '') {
        temp = state.skusf
      }
      state.dates = []
      for (const item of temp) {
        for (const item2 of item.days) {
          state.dates.push(item2.day)
        }
      }
      state.dates = Array.from(new Set(state.dates))
      state.dates = state.dates.filter(v => dayjs(v).unix() >= dayjs().set('hour', 0).set('minute', 0).set('second', 0).add(state.goods.advancePurchaseDays, 'day').unix())
      if (state.dates.indexOf(state.curDate) < 0) state.curDate = ''
    }
    const initCalendar = () => {
      var date = new Date()
      var y = date.getFullYear()
      var m = date.getMonth()
      var temp = []
      for (var i = 0; i < 12; i++) {
        m = m + 1
        if (m % 13 === 0) {
          y = y + 1
          m = 1
        }
        var strM = m < 10 ? '0' + m : m
        var dayCount = new Date(y, m, 0).getDate()
        var day = []
        for (var j = 1; j <= dayCount; j++) {
          var strD = j < 10 ? '0' + j : j
          day.push(y + '-' + strM + '-' + strD)
        }
        temp.push({
          m: y + '-' + strM,
          mAlias: m + '月',
          days: day,
          first: new Date(y + '-' + strM + '-01').getDay()
        })
      }
      state.month = temp
    }
    const onClickText = (index, item, disa) => {
      if (disa) return
      if (index === 0) state.s1 = item
      if (index === 1) state.s2 = item
      if (index === 2) state.s3 = item
      if (index === 3) state.s4 = item
      if (index === 4) state.s5 = item
      console.log('s1:' + state.s1 + ',s2:' + state.s2 + ',s3:' + state.s3 + ',s4:' + state.s4 + ',s5:' + state.s5)
      filterG(1)
    }
    const filterG = (from = 0) => {
      if (state.curDate === '' || from === 1) state.skus = state.skusf
      if (state.s1 !== '') state.skus = state.skus.filter(item => item.s1 === state.s1)
      if (state.s2 !== '') state.skus = state.skus.filter(item => item.s2 === state.s2)
      if (state.s3 !== '') state.skus = state.skus.filter(item => item.s3 === state.s3)
      if (state.s4 !== '') state.skus = state.skus.filter(item => item.s4 === state.s4)
      if (state.s5 !== '') state.skus = state.skus.filter(item => item.s5 === state.s5)
      createS()
      filterDate()
      console.log(state.skus)
      state.price = '选择规格'
      state.skuId = ''
      state.skuDayId = ''
      if (state.skus.length === 1) {
        if (state.curDate !== '' && state.hasDay) {
          const k = state.skus[0].days
          const k2 = k.filter(item => item.day === state.curDate)
          state.price = '￥' + k2[0].price + ''
          state.stock = k2[0].stock
          state.skuId = state.skus[0].skuId
          state.skuDayId = k2[0].skuDayId
          state.ok = true
          // Toast('加载价格')
          console.log('state.skuId:' + state.skuId + ',state.skuDayId:' + state.skuDayId + ',state.qty:' + state.qty)
          return false
        } else if (state.curDate === '' && !state.hasDay) {
          state.price = '￥' + state.skus[0].price + ''
          state.stock = state.skus[0].stock
          state.skuId = state.skus[0].skuId
          state.ok = true
          // Toast('加载价格')
          console.log('state.skuId:' + state.skuId + ',state.skuDayId:' + state.skuDayId + ',state.qty:' + state.qty)
          return false
        } else {}
      } else {
        state.stock = 1
        state.ok = false
      }
    }
    const createS = () => {
      state.s = []
      const col1 = []
      const col2 = []
      const col3 = []
      const col4 = []
      const col5 = []
      for (const item of state.skus) {
        col1.push(item.s1)
        col2.push(item.s2)
        col3.push(item.s3)
        col4.push(item.s4)
        col5.push(item.s5)
      }
      state.s.push(Array.from(new Set(col1)))
      state.s.push(Array.from(new Set(col2)))
      state.s.push(Array.from(new Set(col3)))
      state.s.push(Array.from(new Set(col4)))
      state.s.push(Array.from(new Set(col5)))
    }
    const init = () => {
      post('/product.get', {
        productId: state.gid
      }).then(res => {
        console.log('加载sku内的商品内容')
        if (res.data.specs.length > 0 && res.data.skus.length > 0) {
          for (const item of res.data.specs) {
            state.specs.push(item.name)
          }
          state.goods = Object.assign({}, res.data)
          state.skus = res.data.skus
          state.skusf = res.data.skus
          createS()
          state.fxs = state.s
          initCalendar()
          filterDate()
          for (const item of res.data.skus) {
            if (item.days.length > 0) {
              state.hasDay = true
            }
          }
        } else {
          state.stock = res.data.stock
          state.price = '￥' + res.data.price
          state.ok = true
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onClickText,
      onClickDate,
      onBuy,
      onAddCart,
      onBuy2
    }
  }
}
</script>
<style scoped>
ul {
  overflow:hidden;
}
ul li {
   float:left;
   margin:10px 10px 0 0;
}
.label-available {
  padding:5px 10px;
  background:#f5f5f5;
  border-radius:3px;
  display:inline-block;
  color:#000;
}
.label-disable {
  padding:5px 10px;
  background:#f5f5f5;
  border-radius:3px;
  display:inline-block;
  color:#ccc;
}
.label-active {
  padding:5px 10px;
  background:#ee0a24;
  border-radius:3px;
  display:inline-block;
  color:#fff;
}
.calendar {
  background:#fff;
}
.calendar h5, .calendar h6 {
  text-align: center;
}
.day-available {
  color:#000;
}
.day-disable {
  color:#ccc;
}
.day-active {
  background:#ee0a24;
  color:#fff;
}
.day-active >>> .van-grid-item__content {
  background-color:#ee0a24;
  color:#fff;
}
</style>
